"use client";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
const queryClient = new QueryClient();

import type { PropsWithChildren, FC } from "react";

export const Providers: FC<PropsWithChildren> = function({children}) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
