"use client";

import {
  MantineProvider,
  type MantineThemeOverride,
} from '@mantine/core';
import { type NextFont } from 'next/dist/compiled/@next/font';
import { type FC, memo, type ReactNode } from 'react';

type Props = {
  font?: NextFont;
  children: ReactNode;
};

export const theme: MantineThemeOverride = {
  primaryColor: 'brand',
  defaultRadius: '0.5rem',
  colors: {
    brand: [
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
      '#F7A600',
    ],
  },
  components: {
    InputWrapper: {
      classNames: {
        label:
          'cursor-pointer text-xs font-semibold flex flex-row items-center',
        required: 'ml-0.5',
        error: 'mt-1',
      }
    },
    Input: {
      classNames: {
        wrapper: 'mb-0',
        input:
          'flex h-10 w-full rounded-md border border-input focus:border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      }
    },
    DatePicker: {
      classNames: {
        month: 'w-full',
        calendarHeader: '!max-w-full',
        monthCell: 'text-center',
        day: 'mx-auto',
        decadeLevel: 'w-full',
        yearLevel: 'w-full',
        yearsList: 'w-full',
        monthLevel: 'w-full',
        monthsList: 'w-full',
        pickerControl: '!w-full',
      }
    },
    RadioGroup: {
      classNames: {
        label: "block mb-1",
      }
    },
    Radio: {
      classNames: {
        label: 'cursor-pointer text-base pl-2 font-normal text-grey-700',
        radio: 'cursor-pointer !border-2 !border-black !bg-transparent w-4 h-4',
        icon: 'text-brand',
        inner: 'self-auto',
        body: 'flex flex-row items-center'
      }
    }
  },
};

const MantineCustomTheme: FC<Props> = memo(function ({ font, children }) {
  const fontStyle = font?.style ?? {};
  return (
    <MantineProvider theme={{ ...fontStyle, ...theme }}>
      {children}
    </MantineProvider>
  );
});

MantineCustomTheme.displayName = 'MantineCustomTheme';
export { MantineCustomTheme };
